*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* body {
  background: #fff;
} */

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 500px;
  height: 70vh;
  background: #f1f1f1;
  margin: 50px auto 0;
  word-break: break-all;
  border: 1px solid rgba(0, 0, 0, 0.274);
}

.bloc-tabs {
  display: flex;
}
.tabs {
  padding: 15px;
  text-align: center;
  width: 50%;
  background: rgba(128, 128, 128, 0.075);
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.274);
  box-sizing: content-box;
  position: relative;
  outline: none;
}
.tabs:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.274);
}

.active-tabs {
  background: white;
  border-bottom: 1px solid transparent;
}

.active-tabs::before {
  content: '';
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
  background: rgb(88, 147, 241);
}

button {
  border: none;
}
.content-tabs {
  flex-grow: 1;
}
.content {
  background: white;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: none;
}
.content h2 {
  padding: 0px 0 5px 0px;
}
.content hr {
  width: 100px;
  height: 2px;
  background: #222;
  margin-bottom: 5px;
}
.content p {
  width: 100%;
  height: 100%;
}
.active-content {
  display: block;
}

/* //user login */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

body {
  margin: 0 auto;
}

.m-5-auto {
  margin: 5rem auto;
}

.primary-button {
  margin-top: 5rem;
  margin-right: 1rem;
  padding: 0.6rem;
  width: 10rem;
  background: #222;
  border: none;
  color: #fff;
  font-size: 1.2rem;
  transition: all 0.5s;
  cursor: pointer;
  text-transform: capitalize;
}

/***    Landing Page     ***/
.main-title,
.main-para {
  color: #f1f1f1;
}

.main-title {
  padding-top: 10rem;
  font-size: 5rem;
  font-family: 'Fascinate', cursive;
  text-transform: uppercase;
}

.main-para {
  font-size: 2.5rem;
  text-transform: capitalize;
}

#reg_btn span {
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

#reg_btn span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

#reg_btn:hover span {
  padding-right: 25px;
}

#reg_btn:hover span:after {
  opacity: 1;
  right: 0;
}

/***    Login Page     ***/
h2 {
  font-weight: 300;
}

.user-form {
  display: inline-block;
  background: #f3f3f3;
  border: 1px solid #ddd;
  border-radius: 2px;
  padding: 2rem;
  margin: 1rem 0 1rem 30px;
  width: 80%;
}

form label {
  float: left;
  font-size: 0.9rem;
  margin: 0;
  padding: 0;
  font-weight: 600;
}

.right-label {
  float: right;
  cursor: pointer;
  color: red;
  margin-bottom: 5px;
}

input {
  width: 24rem;
  padding: 0.3rem;
  border-radius: 5px;
  /* outline: none; */
  border: 2px solid black;
}

.sub_btn {
  display: block;
  width: 100%;
  padding: 0.3rem;
  border: none;
  background: #222;
  color: #fff;
  border-radius: 3px;
}

.sub-btn {
  display: block;

  padding: 0.3rem;
  border: none;
  background: #222;
  color: #fff;
  border-radius: 3px;
}

#sub_btn:hover {
  background: #333;
  transition: all 0.5s;
}

footer p {
  margin: 0;
  font-size: 0.9rem;
}

/***    Register Page     ***/
#checkbox {
  width: 1rem;
}

form span {
  font-size: 0.8rem;
}

#reset_pass_lbl {
  float: left;
  font-weight: 600;
}

.label {
  margin-bottom: -2px;
  font-weight: 600;
}

.link-page {
  color: rgb(55, 92, 202);
}

.container-reset {
  width: 500px;
  height: 40vh;
  /* background-color: rgba(185, 6, 6, 0.694); */
  /* background-color: rgb(176, 149, 149); */
  background: #f3f3f3;
  margin: 30px auto;
}

.reset {
  margin-left: 100px;
  margin-top: 30px;
}

.error {
  color: rgb(248, 28, 28);
}

.text-danger {
  color: red;
}

.invalid {
  border: 1px solid red;
}
